@import url('https://fonts.googleapis.com/css?family=Quicksand:regular,bold,italic&subset=latin,latin-ext');

.App {
  text-align: center;
}

/* =================
*  FONT
====================*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* =================
*  NAVBAR
====================*/

.navbar {
  padding: 5px 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #1b1e22;
}

#settings-dropdown::after{
  display: none;
}

/* =================
*  MAIN
====================*/

body {
  background-color: #282c34;
  color: #4e8abb;
  font-family: Quicksand, sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.diagramModal {
  width: 95%;
}

/* =================
* RECOMMENDATIOn TABLE
=================== */

.recommendation-table {
  background-color: #15446a;
  color: "#fefefe";
}
/* =================
* WATCHLIST ITEM
=================== */

.watchlist-item-quick-analysis {
  width: 10%;
  float: right;
  text-align: center;
  font-size: 20px;
  padding-top: 120px;
  height: 100%;

  cursor: pointer;
}

.watchlist-item-quick-analysis:hover {
  background-color: #4e8abb;
  color: rgba(15,17,19,1.0);
  border-radius: 10px;
}

.watchlist-item-details {
  cursor: pointer;
  float: right;
  width: 5%;
  height: 100%;
  background-color: #4e8abb;
  border: solid 1px rgba(15,17,19,0.5);
  color: #4e8abb;
  padding-top: 52px;
  border-left: solid 2px #282c34;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


/* =================
*  AUTHENTICATION
====================*/
.authentication-form {
  max-width: 360px;
  margin: auto;
  border-radius: 3px;
  padding: 10px;
}

/* =================
*  BOARDS
====================*/



.board-title {
  width: 100%;
  padding: 5px 0px;
  font-size: 20px;
  text-align: right;
}

.board-content-box {
  padding: 15px;
  background-color: rgba(33,37,42,0.5);
  margin: auto;
}
.board-text {
  font-size: 22px;
  text-align: center;
}

.form-input {
  background-color: #0617248b;
  border: none;
  border-bottom: 1px #041018e4 solid;
  border-right: 1px #041018e4 solid;
  color: #b4b6d1;
}

.form-input:active{
  background-color: #0617248b;
  border: none;
  border-bottom: 1px #041018e4 solid;
  border-right: 1px #041018e4 solid;
  color: #b4b6d1;
}

.board-form {
  font-size: 20px;
}
.board-half-box {
  width: 45%;
  margin-top: 20px;
  margin-left: 3%;
  margin-right: auto;
  /*display: inline-block;*/
}
.board-half-box-label {
  font-size: 24px;
}
.board-half-box-content {
  padding: 10px;
  background-color: #282c34;
}

/*==============
* BADGES
================ */

/*==============
* SYMBOL CARDS
================ */

.symbol-card-front {
  width: 22%;
  height: 250px;
  margin: 20px;
  float: left;

  background-color: rgba(15,17,19,0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;

  text-align: center;
  display: inline-block;
}

.symbol-card-front-header {
  width: 100%;
  height: 200px;
  padding-top: 20px;
  font-size: 20px;
  border-bottom: solid 1px;
}

.symbol-card-front-header-symbol {
  width: auto;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  background-color: #282c34;
  cursor: pointer;
}

.symbol-card-front-header-symbol:hover {
  background-color: #4e8abb;
  color: rgba(15,17,19,1);
}

.symbol-card-front-body {
  width: 100%;
  height: 50px;
  padding-top: 10px;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.symbol-card-front-body:hover {
  background-color: #4e8abb;
  color:  rgba(15,17,19,1);
}

.symbol-card-detail {
  width: 22%;
  height: 250px;
  margin: 20px;
  float: left;

  color: #0F1113;
  background-color: rgba(78,138,187,0.6);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;

  text-align: center;
  display: inline-block;
}

/* =================
*  Tabs
====================*/

.tab-row {
  width: 100%;
  font-size: 20px;
}

.tab-blocked {
  color: #15446a;
  cursor: not-allowed;
}

.tab-active {
  font-style: italic;
}

.tab-on {
  cursor: pointer;
}