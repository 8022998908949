@import url('https://fonts.googleapis.com/css?family=Quicksand:regular,bold,italic&subset=latin,latin-ext');

/* =================
*  LOG-IN
====================*/

.centered-form {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
  }